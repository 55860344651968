<template>
  <div class="p-grid p-fluid">
    <div class="p-col-12">
      <div class="card">
        <DataTable
            dataKey="id"
            responsiveLayout="scroll"
            filterDisplay="menu"
            :lazy="true"
            :rows="15"
            :paginator="true"
            :value="externalLinks.data"
            :totalRecords="externalLinks.total"
            :rowHover="true"
            :loading="loading"
            @page="query.page = $event.page + 1"
        >
          <template #header>
            <h2>Reservaciones Externas</h2>

<!--            <div class="p-d-flex p-jc-between">-->
<!--              <div class="p-d-flex p-jc-end">-->
<!--                        <span class="p-input-icon-left ">-->
<!--                            <i class="pi pi-search"/>-->
<!--                            <InputText v-model="query.restaurant_search" placeholder="Restaurante"/>-->
<!--                        </span>-->
<!--              </div>-->
<!--            </div>-->
          </template>

          <Column field="id" header="ID"></Column>
          <Column field="restaurant.name" header="Restaurante"></Column>
          <Column header="Referidor">
            <template #body="{ data }">
              <p>{{ data.foodie.name }}</p>
            </template>
          </Column>
          <Column header="IG Referidor">
            <template #body="{ data }">
              <p>{{ data.foodie.ig_username ? '@' + data.foodie.ig_username : '' }}</p>
            </template>
          </Column>
          <Column header="Fecha de visita">
            <template #body="{ data }">
              <p>{{ formatDate(data.created_at) }}</p>
            </template>
          </Column>
          <!--                    <Column field="client_first_name" header="Nombre"></Column>-->
          <!--                    <Column field="client_last_name" header="Apellido"></Column>-->
<!--          <Column header="Nombre">-->
<!--            <template #body="{ data }">-->
<!--              <p>{{ data.client_first_name + ' ' + data.client_last_name }}</p>-->
<!--            </template>-->
<!--          </Column>-->
<!--          <Column field="client_phone" header="Teléfono"></Column>-->
<!--          <Column field="client_email" header="Email"></Column>-->
<!--          <Column field="time" header="Hora"></Column>-->
<!--          <Column field="date" header="Fecha"></Column>-->
<!--          <Column field="people_amount" header="Personas"></Column>-->
<!--          <Column field="solo_link_id" header="Vía">-->
<!--            <template #body="{ data }">-->
<!--              <p v-if="data.related_source === 'solo_link'" style="color: #1D9F25">Sololink</p>-->
<!--              <p v-else style="color: #D02222">Colab</p>-->
<!--            </template>-->
<!--          </Column>-->

          <template #empty>
            No se encontraron reservaciones
          </template>
          <template #loading>
            Cargando reservaciones
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { useRepository } from '../../hooks'
import moment from "moment";

export default {
  name: 'index',
  setup () {
    const externalLinksRepository = useRepository(({sololinks}) => (sololinks))
    const externalLinks = ref([])
    const loading = ref(false)
    const query = ref({
      'limit': 15,
      'paginate': true,
      'page': 1,
      'sort_by': 'id,desc',
    })
    const searchDebounce = ref()

    const formatDate = (date) => {
      return moment(date).format('YYYY-MM-DD HH:mm:00')
    }

    const getExternalLinks = () => {
      loading.value = true
      externalLinksRepository.list(query.value)
          .then(({ data: response }) => {
            externalLinks.value = response
          })
          .finally(() => {
            loading.value = false
          })
    }
    watch(() => query.value.restaurant_search, () => {
      if (searchDebounce.value) {
        clearTimeout(searchDebounce.value)
      }

      searchDebounce.value = setTimeout(() => {
        getExternalLinks();
      }, 600)
    })
    watch(() => query.value.page, () => {
      getExternalLinks()
    })

    onMounted(() => {
      getExternalLinks()
    })
    return {
      externalLinks,
      loading,
      query,
      formatDate
    }
  },
}
</script>

<style scoped>

</style>
